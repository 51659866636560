/*-------------------------*/
/* Board
/*-------------------------*/
.col-chessboard{
    position:relative;
    margin: 0 auto;
    max-width:100%;
}
.with-chessboard-editor{
    overflow:auto;
}
.chessBoard{
    transform: translateZ(0);
    min-width:90px;
    min-height:90px;
    display:block;
    clear:both;
    width:100%;
    height:auto;
    position: relative;
    margin:0 0 0 0;
    padding:0;
    border-radius:2px;
    background:rgba(50,139,164,1);
    overflow:visible;
    border:14px solid rgba(50,139,164,1);
}
.chessBoard.withAnswers{
    width:99%;
    overflow:visible!important;
}
.cbRow{
    clear: both;
}
.cbRow:before,
.cbRow:after {
    content: "";
    display: table;
}
.cbRow:after {
    clear: both;
}
.cbNotation{
    color:rgba(123,132,142,1);
    position:absolute;
    display:block;
    font-size:12px;
    line-height:1.1em;
    height:1em;
    font-weight:400!important;
    font-family: "mr-eaves-modern", Tahoma, Geneva, sans-serif;
}

.cbNotation.cbNumeric{
    color:#fff!important;
    left:-10px;
    top:39%;
}
.cbNotation.cbAlpha{
    color:#fff!important;
    text-transform:uppercase;
    right:42%;
    bottom:-13px;
}
.cbSquare{
    float:left;
    position:relative;
    display:inline-block;
    width:12.5%;
    padding-top:12.5%;
}


/* important stuff for drag and drop to work - START*/
.cbSquare img.ui-draggable{
    position:absolute!important;
    cursor:move;
}
.cbSquare img.ui-draggable-dragging{
    z-index:1000!important;
}
img.ui-draggable-dragging.toBeCloned{
    max-width:12.5%!important;
}
.cbSquare img.ui-draggable:not(.ui-draggable-dragging){
    max-width:100%!important;
    z-index:0;
    top:0!important;
    left:0!important;
}
/* important stuff for drag and drop to work - END*/
.cbSquare.w{
    background-color:rgba(249,249,249,1);
}
html:not(.touch) .cbSquare.w.hover:not(.highlight):hover{
    background:#d4ede9;
}
.cbSquare.b{
    background-color:rgba(50,139,164,1);
}
html:not(.touch) .cbSquare.b.hover:not(.highlight):hover{
    background-color:#8cc9bb;
}
.cbSquare.b .cbNotation{
    color:#fff;
}

@keyframes animateActive {
    0% {
        background-color: #308ba4;
    }
    50% {
        background-color: #c7ecdb;
    }
    100% {
        background-color: #308ba4;
    }
}

.cbSquare.active {
    cursor:pointer;
    animation-name: animateActive;
    animation-duration: 2s;
    animation-delay: 0;
    animation-iteration-count: infinite;
    animation-direction: forward;
}

.cbSquare.cross{
    cursor:pointer;
    background-color: #c72f3f;
}
.chessBoard .cbSquare.marked{
    background-color:rgba(48, 138, 164, 1);
}
/*highlight + correct*/
.chessBoard .cbSquare.b.highlight, .chessBoard .cbSquare.b.correct, .cbSquare.b.drop-area{
    background-color:rgba(140, 201, 187, 1)!important;
}
.chessBoard .cbSquare.w.highlight, .chessBoard .cbSquare.w.correct, .cbSquare.w.drop-area{
    background-color:rgba(199, 236, 219, 1)!important;
}
/*wrong*/
.chessBoard .cbSquare.b.wrong:not(.correct){
    background-color:rgba(199, 47, 64, 1)!important;
}
.chessBoard .cbSquare.w.wrong:not(.correct){
    background-color:rgba(234, 70, 81, 1)!important;

}
.chessBoard .cbSquare.wrong.correct img:not(.check){
    display:none!important;
}

.cbSquare img.img-overlay{
    position:absolute;
    top:0;
    left:0;
    z-index:99999;
}
/* inputs */
.cbSquare input{
    position:absolute;
    top:0;
    background:#fff;
    padding:0;
    width:90%;
    height: 100%;
    z-index:2;
    text-align: center;
    border:none;
    font-size:22px;
    border-bottom:6px double #123;
    color:#123;
    font-weight:700;
    display:inline-block;
}
.cbSquare input:focus{
    border-color: #308ba4!important;
    outline: none;
    box-shadow:none;
}
.cbSquare input.y{
    margin-top:5%;
    margin-left:145%;
}
.cbSquare input.x{
    margin-left:5%;
    margin-top:155%;
}
.cbSquare input.wDiagonal{
    margin-top:155%;
    left:145%;
}
.cbSquare input.bDiagonal{
    margin-top:155%;
    right:145%;
}
.cbSquare input:focus{
   /*border-color:rgba(127, 196, 0, 0.90);*/
}
/*-------------------------*/
/* inputs arrow bg images
/*-------------------------*/
.cbSquare input {
    background-clip: border-box;
    background: url("/assets/img/placeholder-arrows/left.svg") no-repeat 50% 50%,
    linear-gradient(transparent, transparent);
}
.cbSquare input.no-placeholder{
    background:none!important;
}
.cbSquare input.x {
    background: url("/assets/img/placeholder-arrows/up.svg") no-repeat 50% 50%,
    linear-gradient(transparent, transparent);
}
.cbSquare input.bDiagonal {
    background: url("/assets/img/placeholder-arrows/top-right.svg") no-repeat 50% 50%,
    linear-gradient(transparent, transparent);
}
.cbSquare input.wDiagonal {
    background: url("/assets/img/placeholder-arrows/top-left.svg") no-repeat 50% 50%,
    linear-gradient(transparent, transparent);
}
.cbSquare input.noBg{
    background:none;
}
/* Remove input spinner on input type "number" */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    -moz-appearance: textfield;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.chessBoard i, .chessBoard input{
    display:none;
}
.chessBoard.showAllInputs, .chessBoard.withAnswers{
    margin:0 0 14% 0;
}
.chessBoard.showAllInputs i, .chessBoard.showAllInputs input{
    display:block;
}
.chessBoard i.display, .chessBoard input.display{
    display:block;
}

/* pieces */
.cbSquare > img{
    position:absolute;
    z-index:0;
    top:0; left:0; bottom:5px; right:0;
    margin:4%;
    /*box-sizing: border-box;*/
    width:92%!important;
    height:auto!important;
    max-width:none!important;
}
/*-------------------------*/
/* chessBoardEditor
/*-------------------------*/
input.fen-editor-input{
    border: 2px solid #e5e5e5;
    border-radius: 0;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    background: #e5e5e5;
    font-size:11px;
    padding:0 10px 0 10px;
    line-height:16px;
    color:#123;
    height:27px;
    cursor:alias;
    outline-style:none;
}
input.fen:focus {
    outline-style:none;
    box-shadow:none;
}
.chessBoardEditorToolbar{
   /* -webkit-box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.3);
    -moz-box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.3);
    box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.3);
    */
    background-color: #eee; /*rgba(27, 125, 150, 1);*/
    height:auto;
    width:100%;
    box-sizing:border-box;
}
.chessBoardEditorWrp{
    position:relative;
    display:block;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    overflow:inherit;
}
.chessBoardEditorWrp.show-editor-on-hover .chessBoardEditorToolbar{
    position:absolute;
    margin-top:0%;
    margin-top:0%;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    transition:margin-top 0.4s;
}
.chessBoardEditorWrp.editor-above-chessboard .chessBoardEditorToolbar{
    position:absolute;
    margin-top:-13.9%;
}
.chessBoardEditorWrp.editor-under-chessboard .chessBoardEditorToolbar{
    position:absolute;
    bottom:0;
    margin-bottom:-12.5%;
    z-index:7;
}
html:not(.touch)  .chessBoardEditorWrp.show-editor-on-hover:hover .chessBoardEditorToolbar, html:not(.touch)  .chessBoardEditorWrp.show-editor-on-hover:focus .chessBoardEditorToolbar{
    margin-top:-13.9%;
}
.chessBoardEditorToolbar .editorBtn{
    float:left;
    position:relative;
    width:12.5%;
    height:100%;
    display:block;
    background:none;
    /*border-left:2px solid rgba(123,132,142,1);*/
    box-sizing: border-box;
    font-size:18px;
}
html:not(.touch) .chessBoardEditorToolbar .editorBtn:hover{
    background:#8cc9bb;
}

html:not(.touch) .chessBoardEditorToolbar .editorBtn.disabled:hover{
    background:#308ba4!important;
}

.chessBoardEditorToolbar .editorBtn:first-child{
    border:none;
}

.chessBoardEditorToolbar .editorBtn.active{
    background-color: #8cc9bb!important;
}

.chessBoardEditorToolbar .editorBtn.piece img{
    width:80%;
    margin:8% 10% 12% 10%;
    cursor:copy;
}

.chessBoardEditorToolbar .editorBtn img{
    width:100%;
}
/*****************************
    Mirrorring Lines
*****************************/
.line-vertical{
    position:absolute;
    left:0;
    top:0;
    height:100%;
    margin-left:-3px;
    border-left:6px #006186 dashed;
}
.line-horizontal{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    margin-top:-3px;
    border-top:6px #006186 dashed;
}
/*****************************
    Chessboard Lines
*****************************/
.cb-line{
    position:absolute;
    display:block;
    top:0;
    left:0;
    z-index:999;
}
.cb-line.indicate{
    opacity:1!important;
}

.cb-line.horizontal{
    padding-left:100%;
    border-top:6px #006186 dashed;
    margin-top:-3px;
}
.cb-line.vertical{
    height:100%;
    border-left:6px #006186 dashed;
    margin-left:-3px;
}
/*****************************
    Square Lines
*****************************/
.rotate-45{
    -moz-transform: rotate(-45deg);  /* FF3.5+ */
    -o-transform: rotate(-45deg);  /* Opera 10.5 */
    -webkit-transform: rotate(-45deg);  /* Saf3.1+, Chrome */
    -ms-transform: rotate(-45deg);  /* IE9 */
    transform: rotate(-45deg);
    filter: progid:DXImageTransform.Microsoft.Matrix(sizingMethod='auto expand', /* IE6-IE9 */
    M11=0.7071067811865476, M12=0.7071067811865475, M21=-0.7071067811865475, M22=0.7071067811865476);
    zoom: 1;
}
.rotate45{
    -moz-transform: rotate(45deg);  /* FF3.5+ */
    -o-transform: rotate(45deg);  /* Opera 10.5 */
    -webkit-transform: rotate(45deg);  /* Saf3.1+, Chrome */
    -ms-transform: rotate(45deg);  /* IE9 */
    transform: rotate(45deg);
    filter: progid:DXImageTransform.Microsoft.Matrix(sizingMethod='auto expand', /* IE6-IE9 */
    M11=0.7071067811865476, M12=-0.7071067811865475, M21=0.7071067811865475, M22=0.7071067811865476);
    zoom: 1;
}
.squareLine{
    position:absolute;
    z-index:6;
    bottom:50%;
    left:0;
    border-top:3px #921 solid;
    width:100%;
}
/*******************/
/* chessBoardHelp
/******************/
.chessBoardHelp{
    position:fixed;
    z-index:99999;
    color:#fff;
    top:5%;
    left:0;
    text-align:center;
    padding-bottom:40px;
}
.chessBoardHelp h1{
    color:#fff;
}
.chessBoardHelp h1.help-icon{
    text-align: center;
}
.chessBoardHelp h1.help-icon img{
    display:inline-block;
    max-width:90px!important;
}
.chessBoardHelpBox{
    color:#fff;
    padding:2% 3%;
    margin-top:5px;
    font-size:18px;
    text-align: left;
    font-family: "mr-eaves-modern", Tahoma, Geneva, sans-serif;
}
.chessBoardHelpBox strong{
    font-size:18px;
    text-transform: uppercase;
}
.chessBoardHelpBox .answer-box{
    font-weight:700;
    text-size:16px;
}