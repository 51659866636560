/*-------------------------*/
/* General page content
/*-------------------------*/
html, body {
    /*height:auto;*/
    margin:0;
    padding:0;
}

body {
    font-family: Tahoma, Geneva, sans-serif;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 15px;
    line-height: 22px;
    color:#003755;
    position: relative;
    font-weight:400;
}

h1, h2, h3, h4, h5, h6, h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, .btn, [class*="btn-"], button, input[type="submit"], .table thead tr th, .panel-heading, .grid-head, .hl-font, .dss, .nav-tabs>li>a, nav{
    font-family: "mr-eaves-modern", Tahoma, Geneva, sans-serif;
    letter-spacing:1px;
}

h1, h2, h3, h4, h5, h6, h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, .dss{
    font-size: 155%;
    text-transform: uppercase;
}
h6, h6 a, .dss{
    font-size: 135%;
}
h1, h2, h3, h4, h5, h6 {
    margin-bottom: 10px;
    color: #003755;
    font-weight: 700;
    line-height: 1em;
    text-rendering: optimizeLegibility;

}
.btn-back {
    position:relative;
    box-shadow: none!important;
    border:none!important;
    margin-left:10px;
}
.btn-back:not(.btn-auto-width){
    width: -webkit-calc(100% - 10px);
    width:    -moz-calc(100% - 10px);
    width:         calc(100% - 10px);
}
.btn-back:after {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color:transparent;
    border-right-color: inherit;
    border-width: 21px;
    margin-top: -21px;
    margin-right:0;
}

thead{
    line-height: 16px;
    min-height: 26px;
    color: #fff;
    background: #006186;
    font-size: 12px;
    overflow: hidden;
    letter-spacing: 1px;
}

thead tr th{
    text-transform: uppercase;
    font-weight: 300!important;
}
table:not(.dataTable) thead tr th{
    padding: 14px 20px!important;
}

.well{
    box-shadow:none;
    border:none;
    border-radius: 2px;
    background:#f8f8f8;
}
.well.well-dark{
    background:#eee;
}
.inverse-nav-bar{
    background:#eee;
}
.inverse-nav-bar.margin-top{
    padding-top:20px;
    margin-top:50px;
    margin-bottom:0;
}
.inverse-nav-bar .nav-tabs{
    background:#eee;
    text-transform: uppercase;
    letter-spacing:1px;
    font-weight:normal;
    font-size:15px;

}
.inverse-nav-bar .nav-tabs>li.active>a, .inverse-nav-bar .nav-tabs>li.active>a:focus,html:not(.touch) .inverse-nav-bar .nav-tabs>li.active>a:hover,html:not(.touch) .inverse-nav-bar .nav-tabs>li>a:hover, .inverse-nav-bar .nav-tabs{
    border-radius:2px;
    border-color:transparent!important;
}
.inverse-nav-bar .nav-tabs>li.active>a{
    font-weight:700;
}
.nav-pills li a{
    font-family: "mr-eaves-modern", Tahoma, Geneva, sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    border-radius:2px;
    color: #003755;
    letter-spacing:1px;
}
.nav-pills li.active a {
    background: #eee !important;
    border-color: #ddd !important;
    color: #003755!important;
}
.nav-pills li a img{
    height:16px;
    margin-right:5px;
    vertical-align: middle;
}
body.modal-open {
    overflow: hidden!important;
}
img{
    -webkit-user-select:none;
    -webkit-touch-callout:none;
}
a{
    color:#003755;
    outline: 0;
}

html:not(.touch) a:hover, a:visited, a:link, a:active {
    text-decoration: none;
    outline: 0;
}

a.link {
    text-decoration: underline;
}

table td{
    padding:14px 20px!important;
    line-height:28px!important;
}
table th{
    padding:8px 20px!important;
}
table tr.active td{
    background:#c7ecdb!important;
    color:#003755!important;
}
/******************************/
/* surveyModal
 ******************************/
#surveyModal{
    position: fixed;
    z-index: 999999;
    max-height:100%;
    top:0;
    overflow:hidden;
    background:#fff;
    padding:4% 4% 2% 4%!important;
    -webkit-box-shadow: 6px 7px 30px 1px rgba(0,0,0,.62);
    -moz-box-shadow: 6px 7px 30px 1px rgba(0,0,0,.62);
    box-shadow: 6px 7px 30px 1px rgba(0,0,0,.62);
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;
}

#surveyModal #surveyModalClose{
    position:absolute;
    right:4%;
    top:4%;
    color:#003755;
    font-size:32px;
    cursor:pointer;
}

#surveyModal h1.survey-headline{
    margin:0 0 3% 0!important;
    width:85%;
    font-size:32px;
}
#surveyModal .description{
    color:#003755;
    font-size:14px;
    padding:0 0 2% 0;
    margin:0 0 2% 0;
}
#surveyModal .questions{
    margin:0 0 2% 0;
    height:auto;
}

#surveyModal .question-wrp{
    width:100%;
    height:auto;
    display:inline-block;
    padding:5% 3% 3% 4%;
    margin:35px 0 2% 0;
    background:#eee;
    color:#003755;
    font-weight:bold;
    min-height:130px!important;
    position:relative;
}
#surveyModal .question-wrp h3{
    top:0;
    right:0;
    font-size:15px;
    position:absolute;
    margin-top:-30px;
    height:30px;
    line-height:22px;
    padding:2% 3% 3% 0;
    background:#eee;
}
#surveyModal .question{
    overflow:hidden;
}
.slant-left:after {
    content: '';
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background: #eee;
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: skew(-45deg);
    -ms-transform: skew(-45deg);
    transform: skew(-45deg);
    z-index: -1;
}
#surveyModal #postAnswer{
    min-height:130px!important;
    padding:30px;
}
#surveyModal #postAnswer h1 {
    font-size: 30px;
}
@media (max-width: 768px) {
    .question {
        text-align: center;
        padding-bottom:5%;
    }
    .question .text{
        margin-top:3%;
        font-size:22px;
        line-height:26px;
    }
    .question .pull-right, .question .pull-left{
        float:none!important;

    }
}
@media (min-width: 768px) {
    .question .text{
        font-size:24px;
        line-height:30px;
    }
}


/* remove round corners*/
.alert{
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
    text-transform: uppercase;
    font-family: "mr-eaves-modern", Tahoma, Geneva, sans-serif;
    font-weight:700;
    font-size:18px;
    letter-spacing:1px;
}
.alert.absolute{
    position:absolute;
    display:block;
    width:100%;
    left:0;
    top:0;
}
.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  not supported by any browser */
}
/*********************
    Fix inputs & spinner
**********************/
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}
.use-code-btn{
    position:absolute;
    right:7px;
    top:7px;
    height:30px;
}
.input-group-addon{
    border-radius: 0;
}
.input-group-addon.active{
    border:2px solid #70b2a3;
    padding: 5px 12px;
    background-color:#d4ede9;
    border-right:none!important;
}
.form-control{
    border-radius: 2px;
    color:#003755;
    -webkit-box-shadow: none;
    box-shadow:none!important;
    border:3px solid #ddd;
    height:42px;
    font-size:16px;
}
.form-control.active{
    border:3px solid #70b2a3;
    background-color:#d4ede9;
}
select[multiple], select[size] {
    min-height: 44px;
}
.form-control[disabled], .form-control[readonly], fieldset[disabled]{
    background-color:#eee;
}
select.form-control.input-xs{
    font-size:14px;
    line-height:normal;
    height:auto;
}
select.form-control.input-lg{
    line-height:50px;
    height:50px;
}
textarea.form-control{
    height:auto;
    resize: vertical;
    font-weight:normal;
    display:block;
}
.form-control:focus, input:focus{
    -webkit-box-shadow: none;
    box-shadow: none;

}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    font-weight:normal
}

input:-moz-placeholder, textarea::-moz-placeholder { /* Firefox 18- */
    font-weight:normal
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
    font-weight:normal
}

.multi-select-checkboxes label {
    font-size: 16px;
    display: block;
    color: #003755;
    font-weight: 700;
    cursor:pointer;
    line-height:34px;
    position:relative;
    overflow:hidden;
    height:auto;
}
.multi-select-checkboxes label > .fa{
    color:#d3d3d3;
    font-size:24px;
    margin-right:10px;
    float:left;
    line-height:30px;
}
.multi-select-checkboxes label a.open-close{
    color:#003755;
    font-weight:normal!important;
    position:absolute;
    right:15px;
    font-size:24px;
}

.multi-select-checkboxes .more-arrow{
    font-size: 16px;
    display: block!important;
    color: #003755;
    font-weight: 700;
    cursor:pointer;
    width:100%;
    margin-top:2px;
    line-height:22px;
    margin-bottom:20px;
    position:relative;
}
.multi-select-checkboxes .more-arrow:hover{
    color:#456;
}
/*********************
    Scrollbars
**********************/
::-webkit-scrollbar-track{
    background: #f0f1f2;
    border-left:1px solid #dbe0e4;
}
::-webkit-scrollbar-thumb{
    background: #dbdcdd;
}
/*********************
    Colors
**********************/
/* backgrounds */
.bg-light-success{
    background:#c7ecdb!important;
}
.bg-success{
    background:#8cc9bb!important;
}
.bg-warning{
    background:#f8b86e!important;
}
.bg-danger{
    background:#c72f3f!important;
}
.bg-info{
    background:#006186!important;
}
.bg-default{
    background:#eee!important;
}
/* fonts */
.color-light-success{
    color:#c7ecdb!important;
    stroke:#c7ecdb;
}
.color-muted{
    color:#ccc!important;
    stroke:#ccc;
}
.color-success{
    color:#8cc9bb!important;
    stroke:#8cc9bb;
}
.color-strong-success{
    color:#84b0a8!important;
    stroke:#84b0a8;
}
.color-warning{
    color:#f8b86e!important;
    stroke:#f8b86e;
}
.color-danger{
    color:#c72f3f!important;
    stroke:#c72f3f;
}
.color-danger .fa, .color-danger .input-sign{
    color:#c72f3f!important;
}
.color-info{
    color:#134665!important;
    stroke:#134665;
}
/* btns */
.btn{
    text-transform: uppercase;
    font-weight: 700;
    border-radius: 2px;
    color:#fff;
    border:none;
}

.btn-md{
    padding:6px 14px;
    font-size:19px;
    line-height:30px;
}
.btn-xs{
    font-size:14px!important;
    padding-left:6px;
    padding-right:6px;
}
.btn-sm{
    font-size:17px!important;
    padding-left:10px;
    padding-right:10px;
}
.btn.btn-text-left{
    text-align: left;
}
.btn-lg{
    font-size:24px!important;
}
.btn.active, .btn:active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow:none;
}
.btn-success{
    background:#70a69a!important;
    border-color:#70a69a!important;
}
.btn-warning{
    background:#f8b86e!important;
    border-color:#f8b86e!important;
    color:#fff!important;
}
.btn-danger{
    background:#aa3740!important;
    border-color:#aa3740!important;
    color:#fff!important;
}
.btn-info, .btn-primary{
     background:#006186!important;
     border-color:#006186!important;
 }
.btn-info:hover, .btn-primary:hover{
    background:#004e6e!important;
    border-color:#004e6e!important;
}
.btn-info.active{
    background:#308ba4!important;
    border-color:#308ba4!important;
}
.btn-dark{
    background:#003755!important;
    border-color:#003755!important;
}
.btn-dark:hover, .btn-dark:active, .btn-dark:link, .btn-dark:visited{
    background:#000!important;
    border-color:#000!important;
    color:#fff;
}
.btn-tab{
    background:#fff!important;
    border-color:#fff!important;
    color:#456;
    padding:10px 20px;
    font-size:18px;
}
.chart-tools{
    position:absolute;
    top:20px;
    right:20px;
}
html:not(.touch) .btn-tab:hover{
    color:#003755;
}
.btn-tab.active{
    background:#f8f8f8!important;
    border-color:#f8f8f8!important;
    color:#003755;
}
.btn-default{
    background:#e0e0e0!important;
    border-color:#e0e0e0!important;
    color:#003755!important;
}
.btn-default.active{
    border-color:#f8f8f8!important;
    background:#f8f8f8!important;
}
.cke_chrome {
    display: block;
    border: 2px solid #ddd;
    border-radius: 2px;
    padding: 0 3px;
    background: #eee;
}
/*borders*/
.border-success{
    border-color:#8cc9bb!important;
}
.border-danger{
    border-color:#c72f3f!important;
}



/*********************
    Colors
**********************/
.page-wrp{
    min-width:768px;
    margin:0 auto;
    padding:0;
}
/*********************
    White text
**********************/
.txt.fff{
    color:#ccc;
}
.txt.fff a{
    color:#fff!important;
}
.txt.fff h1, .txt.fff h2, .txt.fff h3, .txt.fff h4, .txt.fff h5 {
    color:#fff;
    margin:0 0 15px 0;
}
.txt.fff strong{
    font-weight:normal;
    color:#fff;
}
.txt.fff hr{
    display: block;
    height: 0;
    border: 0;
    border-top:1px solid #89919b;
    margin: 1em 0;
    padding: 0;
}
.txt .bigger{
    font-size:16px;
}
.txt.big{
    font-size:18px;
}
/*-------------------------*/
/* Overlay
/*-------------------------*/
.overlay{
    position: fixed;
    top: 50px!important;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color:rgba(0,47,71,0.90); /*dim the background*/
}
.overlay > span.overlay-close{
    position:absolute;
    z-index:9997;
    top:20px;
    right:25px;
    display:block;
    color:#0b374f;
    font-size:30px;
    cursor:pointer;
}
.overlay > .loading{
     position:absolute;
     top:50%;
     left:0;
     width:100%;
     margin-top:-100px;
     text-align:center;
     font-family: "mr-eaves-modern", Tahoma, Geneva, sans-serif;
    text-transform: uppercase;
     letter-spacing:1px;
     color:#fff;
     font-size:1.8em;
}
.overlay > .loading i.fa{
    display:block;
    margin-bottom:2%;
}

.panel-default{
    margin-bottom:0;
}
.overlay-form, .overlay-info, .overlay-confirm{
    position:fixed;
    z-index:9999;
    top:50%;
    padding:0!important;
    -webkit-box-shadow: 6px 7px 15px 1px rgba(0,0,0,0.42);
    -moz-box-shadow: 6px 7px 15px 1px rgba(0,0,0,0.42);
    box-shadow: 6px 7px 15px 1px rgba(0,0,0,0.42);
}
.overlay-form img, .overlay-info img, .overlay-confirm img{
    max-width:90%;
    margin:5%;
}
 .close{
     filter:alpha(opacity=100);
     opacity:0.7;
     -moz-opacity: 0.7;
     -khtml-opacity:0.7;
     text-shadow:none;
     font-weight:normal;
}
.alert-dismissible  .close{
    margin-right:10px;
}
.overlay-div{
    z-index:9999;
}
.overlay-confirm{
    color:#003755;
}
.overlay-confirm b{
     color:#d80808;
}
.overlay-confirm input{
    width:100%;
    backgroun:#fff!important;
    font-weight:700;
}
.overlay-confirm strong{
    color:#003755;
}
.overlay-confirm .error{
    color:#d80808;
}
.overlay-confirm input.error{
    border-color:#c72f3f!important;
    background:url('/assets/img/alert-input.svg') no-repeat 98% 50%;
    background-size: 25px 25px;
}
/*-------------------------*/
/* alert
/*-------------------------*/
.alert-color{
    color:#d80808;
}
.alert-color-bg{
    color:#d80808;
}
.alert-top{
    padding: 20px 3%;
    margin-top:50px;
    z-index:9999999;
    margin-bottom:-50px;
}

/*-------------------------*/
/* bootstrap theme change
/*-------------------------*/
.container-fluid{
    padding:20px 3% 20px 3%;
}
/*-------------------------*/
/* jquery tooltip style
/*-------------------------*/
.ui-tooltip {
    padding: 8px;
    position: absolute;
    font-size:14px;
    z-index: 9999;
    max-width: 300px;
    -webkit-box-shadow: 0 0 2px #aaa;
    box-shadow: 0 0 2px #aaa;
    background:#fff;
}
/* is needed in order to hide tooltipds*/
.ui-helper-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.panel{
    border:none;
}

.panel > .panel-heading{
    background-color:#308ba4;
    color:#fff;
    text-transform:uppercase;
    font-size: 24px;
    width: 100%;
    font-weight:700;
    line-height: 28px;
    color: #fff;
    padding:10px 20px;
    background-color: #308ba4;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    position:relative;
}
.panel > .panel-heading > .close{
    height:100%;
    position:absolute;
    right:0;
    top:0;
    padding:0 15px;
    width:56px;
    color:#fff;
    background:#004e6e;
    text-align:center;
    line-height:56px;
    font-weight: normal!important;
    text-shadow: none;
    filter:alpha(opacity=100);
    opacity:1;
    -moz-opacity: 1.0;
    -khtml-opacity: 1.0;
}
html:not(.touch) .panel > .panel-heading > .close:hover{
    background-color: #e4de77;
    color: #006186;
}
.panel > .panel-heading i{
    color:#fff;
}
.panel-heading.gray{
    background: #f8f8f8!important;
    border-color: #f8f8f8!important;
    color: #003755;
    padding:20px 20px 0 30px!important;
}

.panel > .panel-body{
    background:#f8f8f8;
    padding: 20px 20px 30px 30px;
}
.panel.gray > .panel-body{
    background:#eee!important;
    font-size:18px;
    color:#003755;
}
label.required:before {
    content: "* ";
    position: relative;
    color: #d35400;
}
label.radio-btn{
    border-radius: 2px;
    border: 2px solid #ddd;
    height: 44px;
    min-width:44px;
    padding:4px;
    background:#fff;
}

.has-error label.radio-btn{
    border-color:#a94442;
}
.has-error .list-group-item{
    border-color:#a94442!important;
}
label.radio-btn img{
    max-width:100%;
}
label.radio-btn.active{
    background-color:#c7ecdb;
    border-color:#8cc9bb;
}
.btn-input, .btn-submit-answer{
    line-height:44px;
    padding:0 10px;
    background:#234;
    border: 1px solid #d3d3d3;
    color:#fff;
    font-weight:700;
    position:absolute;
    top:0;
    border-top-right-radius:4px;
    border-bottom-right-radius:4px;
}
.btn-submit{
    border-radius:4px;
    position:relative;
    top:0;
    right:auto;
}
html:not(.touch) .btn-input:hover, html:not(.touch) .btn-submit:hover{
    background:#003755;
}
.btn-submit-answer{
    position:relative;
    border-radius:4px;
    top:0;
    right:auto;
    line-height:46px;
    font-size:22px;
    padding:0 20px;
}
.btn-submit-answer i{
    margin-top:10px;
}
.control-label{
    color:#003755;
    font-size:14px;
    text-transform: uppercase;
    font-family: "mr-eaves-modern", Tahoma, Geneva, sans-serif;
}
.input-group-addon{
    background-color:#e5e5e5;
    border:none;
    font-weight:600;
    color:#003755;
}
.input-group .form-control[readonly]{
    background:#fff!important;
}
.btn-white{
    background-color: #fff;
    color: #003755;
}
html:not(.touch) .btn-white:hover{
    background-color: #f1f1f1;
}
.alert{
    font-weight:700;
    border:none;
    position:relative;
    z-index:999;
}

.alert-success, .alert-SUCCESS{
    background: #8cc9bb;
    color:#003755;
    background-color:rgba(140, 201, 187, 1)!important;
}
.alert-warning, .alert-WARNING{
    border-color:#e4de77;
    background:#e4de77;
    color:#003755;
}
.alert-error, .alert-ERROR, .alert-danger, .alert-DANGER{
    border-color: rgba(199, 47, 64, 1);
    background-color: rgba(199, 47, 64, 1);
    color: #fff;
}
.alert-info, .alert-INFO{
    background:#308ba4;
    color:#fff;
}
.alert-default, .alert-DEFAULT{
    background:#eee;
    color:#003755;
}
.no-transparency{
    opacity:1.0;
    filter: alpha(opacity=100);
}
@keyframes animateBorder {
    0% {
        border-color: #004e6e;
    }
    50% {
        border-color: #d4ede9;
    }
    100% {
        border-color: #004e6e;
    }
}

/* The element to apply the animation to */
.animate-border{
    animation-name: animateBorder;
    animation-duration: 2s;
    animation-delay: 0;
    animation-iteration-count: infinite;
    animation-direction: forward;
}

@keyframes animateBg {
    0% {
        background-color: #006186;
    }
    50% {
        background-color: #308ba4;
    }
    100% {
        background-color: #006186;
    }
}

/* The element to apply the animation to */
.animate-background{
    animation-name: animateBg;
    animation-duration: 2s;
    animation-delay: 0;
    animation-iteration-count: infinite;
    animation-direction: forward;
}

/*-------------------------*/
/* grid
/*-------------------------*/
.grid-wrp{
    position:relative;
}
.grid-head{
    position:relative;
    font-size:21px;
    width:100%;
    font-weight:700;
    line-height:22px;
    padding:8px 10px 8px 10px;
    text-transform:uppercase;
    color:#fff;
    background-color:#308ba4;
    border-top-right-radius:2px;
    border-top-left-radius:2px;
}

.grid-head img{
    height:21px;
    display:inline-block;
}

.grid-head .form-control.grid-filter {
    border-radius: 2px;
    border:none!important;
    color: #003755;
    -webkit-box-shadow: none;
    margin:0!important;
    float:right;
    font-size: 16px;
    padding:2px 8px!important;
    height:24px!important;
}

.grid-head.gray{
    background:#f4f5f7;
    color:#003755;
    text-transform:none;
}
.grid-head a.fa, .grid-head i.fa {
    font-size: 16px;
    position:relative;
    margin:0 0 0 4px;
}
.grid-head a.fa span{
    position:absolute;
    bottom:-4px;
    right:-8px;
    padding:1px 3px;
    border-radius: 5px;
    font-size:10px;
    font-family:monospace;
    text-align:center;
    color:#fff;
}
html:not(.touch) .grid-head a:hover{
    color:#e4de77
}
.grid-head .search .input-group-addon{
    padding:0 10px 0 0;
}
.grid-head .search input{
    border:none;

}
.grid-wrp .filter{
    padding-bottom:10px;
}
.grid-wrp .filter input, .grid-wrp .filter select{
    font-family: Tahoma, Geneva, sans-serif;
}
.grid-wrp .filter div{
    margin-top:20px;
    font-weight:100;
    text-transform: none;
}
.grid-head select{
    margin:-6px -4px 0 0;
    display:inline-block;
    line-height:18px;
    border-radius:2px;
    border:none!important;
    padding:4px;
}
.grid-head a{
    color:#fff;
}
/*/assets/img/ajax-loader.gif*/
.grid{
    position:relative;
    width:100%;
    background:#f4f5f7;
    border-top:none;
    overflow:hidden;
    display:block;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
}

.grid-exchange{
    position:absolute;
    top:50%;
    margin-left:-20px;
}
.grid-row-header{
    line-height:16px;
    min-height:26px;
    color:#fff;
    background:#006186;
    font-size: 13px;
    font-family: "mr-eaves-modern", Tahoma, Geneva, sans-serif;
    text-transform: uppercase;
    font-weight:300;
    overflow:hidden;
    letter-spacing: 1px;
}

.grid-row-header a{
    color:#fff;
}
.grid-row-header a{
    white-space:nowrap;
}
.grid-row-header a [class*=" fa-sort"]{
    color:#308ba4;
}
.grid-row-header a [class*=" fa-sort-"]{
    color:#cbb16e;
}
.grid-row{
    position:relative;
    word-wrap: normal!important;
    line-height:18px;
    min-height:18px;
    background:#f8f8f8;
    overflow:hidden;
}

.grid-head, .grid-row, .grid-row-header {
    padding:14px 20px !important
}
.grid-row-header.no-bg{
    color:#003755;
    background:#fff;
    min-height:20px;
    padding:10px 5px !important
}
.grid-row-header:not(.no-bg) a{
    color:#fff;
}
/*
.grid-row.tilt-row {
    border-top:1px solid #e5e8ea!important;
}
*/
.grid-row:not(.grid-inverse):nth-child(even) {
    background:#eeeeee;
}
.grid-row.grid-inverse{
    background:#eeeeee;
}
.grid-row.grid-inverse:nth-child(even) {
    background:#f8f8f8;
}

.grid-row:first-child {
     border-top:none;
}

.grid-row .fa{
    font-size:14px;
}

.grid-row > .lv2{
    text-indent:5px;
}
.grid-row > .lv3{
    text-indent:20px;
}
.grid-row .group-code{
    position:relative;
    z-index:99;
    margin-top:-3px;
}

.grid-row div[class*="col-"], .grid-row-header div[class*="col-"] {
    display:inline-block;
}

.dataTables_wrapper .row:first-child {
    margin:0;
    padding:5px 0 4px 0;
    background:none;
    background:#006186;
    color:#fff;
    font-family: "mr-eaves-modern", Tahoma, Geneva, sans-serif;
}
.dataTables_wrapper table.dataTable{
    margin-top:0!important;
}
.dataTables_wrapper .row:last-child {
    font-family: "mr-eaves-modern", Tahoma, Geneva, sans-serif;
}
.dataTables_filter{
    float:right!important;
}
.dataTables_filter label input{
    height:18px;
    line-height:18px;
    border:none!important;
    font-size: 12px;
    font-family: Tahoma, Geneva, sans-serif;
}
.col-label{
    white-space:nowrap;
    overflow:hidden;
    font-size:12px;
    font-weight:600;
    padding-left:0;
}
.grid.no-padding .grid-row-header, .grid.no-padding .grid-row{
    padding:3px 5px 4px 5px;
}
span.valueBall{
    border-radius:18px;
    padding:3px;
    text-align:center;
    font-size:16px;
    line-height:20px;
    width:24px;
    height:24px;
    color:#fff;
    margin-right:5px;
    font-family: "mr-eaves-modern", Tahoma, Geneva, sans-serif;
    float:none!important;
    display:inline-block;
}
ul.valueBall-descriptionLine{
    list-style-type:none;
    padding:0;
    text-align: left;
}
ul.valueBall-descriptionLine li{
    margin:5px 10px 0 0;
    display:inline-block;
    line-height:18px;
    font-size:12px;
    font-weight:bold;
    color:#003755;
    text-transform: uppercase;
}
ul.valueBall-descriptionLine li span{
    display:inline-block;
    vertical-align: middle;
}
.grid-row.cursor-y-move{
    cursor:n-resize;
}
.grid-row.cursor-y-move{
    cursor:n-resize;
}
.grid-row.cursor-x-move{
    cursor:w-resize;
}
.grid-row.picked{
    z-index:999;
    background-color:#c7ecdb!important;
}

.grid-row.picked a, .grid-row.picked a i.fa{
    color:#003755!important;
}
.grid-row a{
    position:relative;
    z-index:999;
    font-size:14px;
}
.grid-row a img, .grid-row img{
     height:22px;
}
.grid-row .tools{
    float:right;
}
.cb-min-width .grid-row{
    min-width:25%;
}


/*-------------------------*/
/* search
/*-------------------------*/
.search input{
    border:5px solid #308ba4;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    font-family: Tahoma, Geneva, sans-serif;
    font-weight:normal;
}
.search .input-group-addon{
    background-color:#308ba4;
    color:#fff;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}
ul.ui-autocomplete{
    background:#fff;
    font-weight:normal!important;
    text-transform: none;
    border:2px solid #66afe9;
    border-top:none;
    font-family: Tahoma, Geneva, sans-serif;
    font-size: 14px;
    line-height:22px;
    list-style: none;
    color:#003755;
    max-width:inherit;
    z-index:9999;
}
ul.ui-autocomplete li{
    padding:0 5px;
    text-indent: 5px;
}
li.ui-menu-item.ui-state-focus{
    background-color:#c7ecdb!important;
}
/*******************************************
/* btns for cb-lines & quiz
/*******************************************/
.label-btn{
    display:inline-block;
    padding:4px 10px;
    font-size:22px;
    border-radius:4px;
    cursor:pointer;
    color:#003755;
    line-height:40px;
    overflow:hidden;
    vertical-align:middle;
    font-weight:bold;
}

.label-btn .fa{
    font-weight:normal;
    display:inline-block;
}
.label-btn.label-sign{
    border:2px solid #003755;
    border-radius:2px;
    line-height:22px;
    font-size:18px;
    text-align: center;
    height:24px;
    overflow:hidden;
    width:24px;
    padding:0;
    font-weight:700!important;
    margin:3px 5px;
}
.label-btn.label-sign .fa{font-weight:700!important;padding:0;}
.label-btn.label-sign .input-sign{font-weight:700!important;line-height:15px;padding:0}
.label-btn.label-sign.active{
    background:#003755!important;
    color:#fff!important;
}

/*****************************
    Chartist
*****************************/
.ct-grids line {
    color: #456;
}
.ct-labels span {
    color: #456;
}
.ct-label {
    color: #003755!important;
    font-size: 1.1rem!important;
    line-height: 1;
}
.ct-chart-donut .ct-label{
    fill: #fff!important;
}
/*****************************
    Group code
*****************************/
.group-code-big{
    padding:30px 0 50px 0;
    color:#fff;
    overflow:hidden;
    width:100%;
    height:100%;
}
.group-code-big div{
    color:#fff;
    font-size:106px;
    padding:50px 20px 5px 20px;
    font-weight:700;
    display:block;
}
.group-code-big small{
    margin-top:30px;
    display:inline-block;
}

/*****************************
    License overview
*****************************/
.license-overview h6.hl-file{
    margin:0;
    font-size:18px;
}
.license-overview h6.hl-package{
    font-size:22px;
    margin-top:0;
}
.license-overview .panel-body{
    padding:20px;
    font-size:20px;
    font-weight:bold;
    color:#003755;
}

/*****************************
    Missing/error page
*****************************/

.missing h1, .error h1{margin:0;font-size:30px;font-size:6.5vw;color:#fff;}
.missing h2, .error h2{margin:20px 0 30px 0;font-size:20px;line-height:26px;font-weight:700;color:#fff;}
.missing h2 a, .error h2 a{color:#fff;text-decoration: none;}
.missing strong, .error strong{color:#fff;}
.missing a, .error a{font-weight:700;color:#fff;text-decoration: none;}

.missing .info, .error .info{margin-top:10px; }
.redirects{
    margin-top:10px
}
.redirects a.link{
    font-family: "mr-eaves-modern", Tahoma, Geneva, sans-serif;
    text-transform: uppercase;
    margin:1%;
    background:#002f47;
    display:inline-block;
    width:200px;
    padding: 15px 0px;
    color:#fff;
    font-weight:700;
    text-decoration: none;
}


switch > input[type="checkbox"] {
    display: none;
}

.cb-switch{
    float:right;
}
.list-group-item label:first-child{
    margin:0!important;
    padding:0!important;
    color:#003755;
    font-weight:100;
}
.list-group-item:not(.no-border-top):first-child{
    border-top: 3px solid #ddd;
}
.list-group-item{
    border-radius:2px!important;
    border:3px solid #ddd;
    border-top:none;
}
.cb-switch > input {
    display:none;
}
.cb-switch > label {
    cursor: pointer;
    height: 0;
    position: relative;
    width: 40px;
}

.cb-switch > label::before {
    background: #ddd;
    border-radius: 8px;
    content: '';
    height: 16px;
    margin-top: -8px;
    position:absolute;
    transition: all 0.4s ease-in-out;
    width: 40px;
}
.cb-switch > label::after {
    background: #fff;
    border:2px solid #ddd;
    border-radius: 16px;
    content: '';
    height: 24px;
    left: -4px;
    margin-top: -8px;
    position: absolute;
    top: -4px;
    transition: all 0.3s ease-in-out;
    width: 24px;
}
.cb-switch > input[type="checkbox"]:checked + label::before {
    background: inherit;
    opacity: 0.5;
}
.cb-switch > input[type="checkbox"]:checked + label::after {
    background: inherit;
    border:none;
    left: 20px;
}
.list-group{
    border-radius: 0;
}
.label-success{
    background-color: #70b2a3;
}
.label-success::after{
    border:none;
}

.student-trophy{
    font-family: "mr-eaves-modern", Tahoma, Geneva, sans-serif;
}
.student-trophy .progress{
    border-radius: 2px;
    box-shadow:none;
    background:#eee;
}
.student-trophy .progress-bar{
    border-radius: 2px;
    box-shadow:none;

}
.rating {
    display: inline-block;
}
.rating input {
    border: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    opacity: 0;
}

.rating label {
    position: relative;
    float: right;
    color: #C8C8C8;
    margin:0;
}

.rating label:before {
    margin: 20px 5px;
    content: "\f005";
    font-family: FontAwesome;
    display: inline-block;
    font-size:3em;
    color: #ccc;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.rating input:checked ~ label:before {
    color: #f8b86e;
}

.rating:not(.no-hover) label:hover ~ label:before {
    color: #ffd78b;
}

.rating:not(.no-hover) label:hover:before {
    color: #f8b86e;
}
.rating.small label:before{
    margin:0;
    font-size:14px;
}
.rating label.filled:before{
    color:#f8b86e!important;
}