/*-------------------------*/
/* General bootstrap addons
/*-------------------------*/
/* padding */
.row-no-padding [class*="col-"] {padding: 0 auto;}
.no-padding-top {padding-top: 0!important;}
.no-padding-bottom {padding-bottom: 0!important;}
.no-padding-left {padding-left: 0!important;}
.no-padding-right {padding-right: 0!important;}
.no-padding {padding: 0!important;}
.no-padding-x {padding-left: 0;padding-right: 0;}
.no-padding-y {padding-top: 0;padding-bottom: 0;}
.row-set-padding {padding-left: 19px;padding-right: 19px;}
.row-set-padding [class*="col-"] {padding-left: 5px;padding-right: 5px;}
.padding-right {padding-right:17px}
.padding-double-right {padding-right:34px!important}
.padding-left {padding-left:17px}
.padding-double-left {padding-left:34px}
.padding-bottom {padding-bottom:17px}
.small-padding-bottom {padding-bottom:5px}

/* margin */
.row-no-margin [class*="col-"] {margin: 0;}
.no-margin-top {margin-top:0!important;}
.no-margin-bottom {margin-bottom:0!important;}
.no-margin-left {margin-left: 0;}
.no-margin-right {margin-right: 0!important;}
.no-margin-x {margin-left: 0;margin-right: 0;}
.no-margin-y {margin-top: 0;margin-bottom: 0;}
.no-margin {margin:0;}
.small-margin-top{ margin-top:5px;}
.small-margin-bottom{ margin-bottom:5px;}
.small-margin-left{margin-left:3px;}
.small-margin-right{ margin-right:3px;}
.margin-top{ margin-top:15px;}
.margin-top-small{ margin-top:5px;}
.margin-right {margin-right:17px}
.margin-left {margin-left:17px}
.margin-bottom{ margin-bottom:20px;}
/* text-align */
.text-align-right{ text-align: right}
.text-align-left{ text-align: left}
.text-align-center{ text-align: center}
/* center */
.center{text-align:center}
.inline-block{display:inline-block;float:none}
/* wrapping */
.no-wrap{white-space: nowrap}
/* borders */
.no-border{border:none!important}
.no-border-top{border-top:none!important;}
.no-border-left{border-left:none!important;}
.no-border-right{border-right:none!important;}
.no-border-bottom{border-bottom:none!important;}
.hidden{display:none}
/*space-v-splitters*/
.space-bottom{margin-bottom:40px;}
.relative{position:relative!important}
.inline{display:inline;}
/*relative*/
[class*="relative-"]{position:relative}
.relative-left{top:0;left:0;}
.relative-right{top:0;right:0;}
/*absolute*/
[class*="absolute-"]{position:absolute}
.absolute-left{top:0;left:0;}
.absolute-right{top:0;right:0;}
.cursor-pointer{cursor:pointer}
.cursor-not-allowed{cursor: not-allowed!important}
.pointer-events-auto{
    pointer-events: auto!important;
}
.dont-break-out {

    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;

}
.bold{font-weight:bold}
img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
.v-center {
    display: inline-block;
    vertical-align: middle;
    float: none;
}
.alert-dismissable .close, .alert-dismissible .close{
    vertical-align: middle;
    text-shadow:none!important;
    padding-right:5px;
    color:inherit!important;
    line-height: normal!important;
    opacity:1!important;
}
.btn{
    border-radius: 2px;
}

/*-------------------------*/
/* Tables
/*-------------------------*/
.table>thead>tr>th{
    color:#fff!important;
    text-transform:uppercase;
}
.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
    border:none!important;
}

.table-striped>tbody>tr{
    background-color: #eee;
}
.table-striped>tbody>tr:nth-of-type(odd) {
    background-color: #f8f8f8;
}
/*-------------------------*/
/* FAQ & Info(Help) pages
/*-------------------------*/
.navbar-default .navbar-collapse, .navbar-default .navbar-form{
    border:none;
}

nav.auth-nav {
    position:relative;
    left:0;
    top:0;
    min-width:100%!important;
    background-color:#308ba4;
    background-color:rgba(48,139,164);
    box-sizing: border-box;
    margin-bottom:0;
    z-index:99999;
    overflow-x:hidden;
    border:none;
    padding-right:0;
    border-radius: 0;
}
nav.auth-nav .navbar-toggle{
    margin-top:-1px;
    border:2px solid #fff;
    border-radius: 2px;
}
nav.auth-nav .navbar-collapse{
    padding-right:0;
}
nav.auth-nav a{
    font-size:14px!important;
    font-weight: 700!important;
    text-transform: uppercase;
}
@media screen and (min-width: 767px) {
    nav.auth-nav .navbar-collapse:not(.in) .uni-login{
        background-color:#002f47;
        padding:0 25px 0 25px;
        margin-left:15px;
        line-height:51px;
    }

    nav.auth-nav .navbar-collapse:not(.in) a.uni-login {
        color: #fff;
        font-weight: bold;
    }

    html:not(.touch) nav.auth-nav .navbar-collapse:not(.in) a.uni-login:hover {
        color: #123 !important;
        background-color: #e4de77;
    }
}
nav.auth-nav .navbar-nav{
    text-align:left;
    border:none;
    overflow:hidden;
    margin:0;
    padding:0 0 0 0;
}
nav.auth-nav .navbar-brand img{
    height:46px;
    margin:-13px 0 0 0;
}
nav.auth-nav button.navbar-toggle{
    background:none!important;
    margin-top:11px;
    padding:6px;
    border:2px solid #fff;
}
html:not(.touch) nav.auth-nav button.navbar-toggle:hover {
    background:none!important;
}
nav.auth-nav .navbar-toggle .icon-bar{
    background:#fff;
}
nav.auth-nav .navbar-nav a{
    font-size:14px;
    font-weight:bold;
    font-family: "mr-eaves-modern", Tahoma, Geneva, sans-serif;
    color:#fff!important;
}
html:not(.touch) nav.auth-nav .navbar-nav a:hover{
    color:#e4de77!important;
}
nav.auth-nav .navbar-collapse{
    border:none!important;
    text-align:right;
}
nav.auth-nav .navbar-collapse.in{
    position:relative!important;
    text-align:left;
}
nav.auth-nav .navbar-collapse.collapse:not(.in) .navbar-nav{
    float:right;
}

/*-------------------------*/
/* After login
/*-------------------------*/
nav.blue-nav {
    min-width:100%!important;
    background-color:#308ba4;
    box-sizing: border-box;
    margin-bottom:0;
    z-index:99999;
    overflow-x:hidden;
    position:fixed;
    top:0;
    left:0;
    border:none;
    padding-right:0;
    border-radius: 0;
}
nav.blue-nav .navbar-toggle{
    margin-top:-1px;
    border:2px solid #fff;
    border-radius: 2px;
}
nav.blue-nav .navbar-toggle span.icon-bar{
    height:2px;
    border-radius:0;
}
nav.blue-nav a{
    line-height:22px;
    text-transform: uppercase;
    font-size: 14px!important;
    font-weight:700;
    letter-spacing: 1px;
    color:#fff;
}
nav.blue-nav div.nav-text-wrp{
    display:inline-block;
    padding:14px 21px;
    line-height:22px;
    text-transform: uppercase;
    font-size: 14px!important;
    font-weight:400;
    letter-spacing: 1px;
    color:#fff
}
nav.blue-nav div.nav-text-wrp i.fa{
    font-size: 16px!important;
}
nav.blue-nav li>a>img{
    max-height:28px!important;
    max-width:28px!important;
}
nav.blue-nav a span{
   font-weight:lighter!important;
}
nav.blue-nav .navbar-collapse[aria-expanded="true"]{
    background:#004e6e!important;
    padding-bottom:20px;
}
nav.blue-nav .navbar-right{
    margin:0;
}
nav.blue-nav .navbar-nav a{
    padding:0 8px;
    line-height:51.4px;

}

nav.navbar-default .navbar-nav a{
    color:#fff;
    font-weight:bold;
    font-family: "mr-eaves-modern", Tahoma, Geneva, sans-serif;
}
.fixed-nav-margin{
    padding-top:80px!important;
}
nav.blue-nav .navbar-nav>li>a{
    color:#fff;
}
html:not(.touch) .navbar-nav>li>a:hover, html:not(.touch) .navbar-nav>li>a:active{
    color:#e4de77;
}
nav.blue-nav .navbar-brand img{
    height:38px;
    margin:-15px 0 0 10px;
}
nav.blue-nav .navbar-brand img.logo-gambit{
    height:18px;
    margin:0;
}
nav.blue-nav button.navbar-toggle{
    background:none!important;
    margin-top:11px;
    padding:6px;
}
html:not(.touch) nav.blue-nav button.navbar-toggle:hover {
    background:none!important;
}
nav.blue-nav .navbar-toggle .icon-bar{
    background:#fff;
}
nav.blue-nav .navbar-collapse{
    width:100%;
}
@media screen and (min-width: 767px) {
    nav.blue-nav .navbar-collapse:not([aria-expanded="true"]) a.my-page {
        background: #006186;
        padding: 0 15px !important;
        margin-left: 10px;
    }

    html:not(.touch) nav.blue-nav .navbar-nav a:hover {
        color: #e4de77;
    }

    nav.blue-nav .navbar-collapse:not([aria-expanded="true"]) a.log-out,
    nav.blue-nav .navbar-collapse:not([aria-expanded="true"]) a.dashboard,
    nav.blue-nav .navbar-collapse:not([aria-expanded="true"]) a.close-window,
    nav.blue-nav .navbar-collapse:not([aria-expanded="true"]) .navbar-nav a.gear {
        position: relative;
        background-color: #004e6e;
        height: 51px;
        width: 51px;
        margin: 0;
        padding: 0;
    }

    nav.blue-nav .navbar-collapse:not([aria-expanded="true"]) a.close-window {
        width: auto;
        background-color: #002f47;
        padding: 0 15px;
    }
    nav.blue-nav .navbar-collapse:not([aria-expanded="true"]) .navbar-nav a.gear {
        background-color: #00597e;
    }
    nav.blue-nav .navbar-collapse:not([aria-expanded="true"]) a.log-out {
        background-color: #002f47;
    }

    html:not(.touch) nav.blue-nav .navbar-collapse:not([aria-expanded="true"]) a.my-page:hover, html:not(.touch) nav.blue-nav .navbar-collapse:not([aria-expanded="true"]) a.my-page:active,
    html:not(.touch) nav.blue-nav .navbar-collapse:not([aria-expanded="true"]) a.gear:hover, html:not(.touch) nav.blue-nav .navbar-collapse:not([aria-expanded="true"]) a.gear:active,
    html:not(.touch) nav.blue-nav .navbar-collapse:not([aria-expanded="true"]) a.close-window:hover, html:not(.touch) nav.blue-nav .navbar-collapse:not([aria-expanded="true"]) a.close-window:active,
    html:not(.touch) nav.blue-nav .navbar-collapse:not([aria-expanded="true"]) a.log-out:hover, html:not(.touch) nav.blue-nav .navbar-collapse:not([aria-expanded="true"]) a.log-out:active,
    html:not(.touch) nav.blue-nav .navbar-collapse:not([aria-expanded="true"]) a.dashboard:hover, html:not(.touch) nav.blue-nav .navbar-collapse:not([aria-expanded="true"]) a.dashboard:active {
        background-color: #e4de77!important;
        color: #006186;

    }

    html:not(.touch) nav.blue-nav .navbar-collapse:not([aria-expanded="true"]) a.toggle-img:hover img#first {
        display: none !important;
    }

    html:not(.touch) nav.blue-nav .navbar-collapse:not([aria-expanded="true"]) a.toggle-img:hover img#second {
        display: block !important;
    }

    nav.blue-nav .navbar-collapse:not([aria-expanded="true"]) a.gear img {
        position: absolute;
        max-height: 28px;
        max-width: 28px;
        top: 50%;
        left: 50%;
        margin-left: -14px;
        margin-top: -14px;
    }

    nav.blue-nav .navbar-collapse:not([aria-expanded="true"]) a.log-out img, nav.blue-nav .navbar-collapse:not([aria-expanded="true"]) a.dashboard img  {
        position: absolute;
        max-height: 20px;
        max-width: 20px;
        margin-left: -14px;
        left: 50%;
        top: 50%;
        margin-top: -14px;
    }
}